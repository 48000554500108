var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vehicleItem &&
    _vm.vehicleItem.cameras &&
    _vm.vehicleItem.cameras.length > 0
    ? _c(
        "div",
        { staticClass: "play" },
        [
          _c(
            "a-button",
            {
              staticClass: "play-btn",
              attrs: { icon: _vm.isPlaying ? "pause-circle" : "play-circle" },
              on: { click: _vm.handlePlay },
            },
            [_vm._v("车端视频")]
          ),
          _vm.isPlaying
            ? _c(
                "div",
                { staticClass: "real-vedio-player" },
                [
                  _c("revo-video-player", {
                    ref: "vplayerRef",
                    attrs: {
                      direction: _vm.direction,
                      url: _vm.videoUrl ? _vm.showVedioUrl : "",
                    },
                    on: { handleError: _vm.handleError },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }