<template>
  <div class="play" v-if="vehicleItem && vehicleItem.cameras && vehicleItem.cameras.length > 0">
    <a-button :icon="isPlaying ? 'pause-circle' : 'play-circle'" class="play-btn" @click="handlePlay">车端视频</a-button>
    <div class="real-vedio-player" v-if="isPlaying">
      <revo-video-player ref="vplayerRef" @handleError="handleError" :direction="direction" :url="videoUrl ? showVedioUrl : ''" />
    </div>
  </div>
</template>
<script>
import api from '@/api/iot/vehicle'
import RevoVideoPlayer from '@/views/monitor/monitorForm/vehicle/funcList/modules/RevoVideoPlayer'
export default {
  components: {
    RevoVideoPlayer
  },
  props: {
    vehicleItem: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      url: api.videoUrlList[0],
      activeCamera: {},
      cameras: [],
      direction: '',
      videoUrl: null,
      isPlaying: false
    }
  },

  computed: {
    showVedioUrl() {
      console.log('showVedioUrl')
      if (!this.videoUrl) {
        return ''
      }
      if (this.videoUrl.indexOf('http') === 0) {
        return this.videoUrl
      } else {
        return this.url + this.videoUrl
      }
    }
  },
  watch: {
    'vehicleItem.vehicleId'(newVal, oldVal) {
      this.isPlaying = false
      this.cameras = []
      this.activeCamera = null
      this.videoUrl = null
    },
    activeCamera(newVal, oldVal) {
      console.log('activeKey', newVal)
      // 先释放
      try {
        if (oldVal) {
          this.$refs.vplayerRef.reset()
        }
      } catch (e) {}
      if (newVal) {
        this.videoUrl = ''
        this.$nextTick(() => {
          this.videoUrl = newVal.cameraAddr
        })
      }
    }
  },
  mounted() {
    console.log('video')
  },
  methods: {
    handlePlay() {
      this.isPlaying = !this.isPlaying
      if (this.isPlaying) {
        this.getFlv('init')
      }
    },
    getFlv(type) {
      if (this.vehicleItem.cameras && this.vehicleItem.cameras.length === 0) {
        this.cameras = []
        this.activeCamera = null
        this.videoUrl = null
        return
      }
      postData(this.url, api.ffmpegStart, this.vehicleItem.cameras)
        .then((data) => {
          console.log('视频流地址result:', data)
          if (type === 'init') {
            this.cameras = data.map((d) => {
              d['cameraVisible'] = false
              if (d['mainCamera'] === 'yes') {
                console.log('mainCamera:', d)
                this.videoUrl = d['cameraAddr']
                this.activeCamera = d
                this.direction = d.id
              }
              return d
            })
          } else {
            for (const item of this.cameras) {
              if (item.id === type) {
                for (const dItem of data) {
                  if (item.id === dItem.id) {
                    if (item['mainCamera'] === 'yes') {
                      this.videoUrl = dItem.cameraAddr
                      this.activeCamera = dItem
                      this.direction = dItem.id
                    }
                    item.cameraAddr = dItem.cameraAddr
                    break
                  }
                }
              }
            }
          }
          this.cameras = data.map((d) => {
            d['cameraVisible'] = false
            return d
          })
        }) // JSON from `response.json()` call
        .catch((error) => console.error(error))
      function postData(url, requestPath, data) {
        const requestUrl = url + requestPath
        // Default options are marked with *
        return fetch(requestUrl, {
          body: JSON.stringify(data), // must match 'Content-Type' header
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, same-origin, *omit
          headers: {
            'content-type': 'application/json',
            // 'Authorization': 'Basic YWRtaW46YWRtaW4='
            Authorization: 'Basic YWRtaW46YWRtaW4jMjAyMDEyMTU='
          },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer' // *client, no-referrer
        }).then((response) => response.json()) // parses response to JSON
      }
    },
    handleError(item) {
      this.getFlv(item.direction)
    }
  }
}
</script>

<style lang="less" scoped>
.play-btn {
  margin: 0 10px 10px 10px;
}
.real-vedio-player {
  padding: 10px;
  padding-top: 0;
  height: 200px;
}
.play {
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: saturate(180%) blur(15px);
  -moz-backdrop-filter: saturate(180%) blur(15px);
  backdrop-filter: saturate(180%) blur(15px);
}
</style>
